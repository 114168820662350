.transactions {
    width: calc(100% - 80px);
    height: 40px;
    margin: 20px 20px 0 20px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    background-color: #CBD8EC;
    color: black;
    border-radius: 5px;
}