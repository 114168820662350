.home{
    width: 100%;
    margin-top: 20px;
}
.homeWidgets{
    display: flex;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
}
@media only screen and (max-width:700px)  {
    .home{
        width: 100vw;
    }
}