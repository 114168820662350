.topbar {
    width: 100%;
    height: 60px;
    /*background-color: #38444d;*/
    background-color: #1A2343;
    position: sticky;
    top: 0;
    z-index: 999;
    overflow: auto;
    -webkit-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
}

.topbar_hidden {
    width: 100%;
    height: 50px;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 999;
    overflow: auto;
    pointer-events: none;
}

.topbarWrapper {
    height: 100%;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-weight: bold;
    font-size: 30px;
    cursor: pointer;
    color: darkblue;
}

.topRight {
    display: flex;
    align-items: center;
    /*padding: 10px 0px;*/
}

.topLeft {
    display: flex;
    align-items: center;
    /*padding: 10px 0px;*/
}

.topbarIconContainer {
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    color: #555;

}

.topIconBadge {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 0px;
    background-color: red;
    color: black;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;


}

.topAvatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
}

.reorder {
    /*display: none;*/
}

@media only screen and (max-width: 700px) {
    .topbar {
        width: 100vw;
    }

    .topbar_hidden {
        width: 100vw;
    }

    .reorder {
        margin-right: 5px;
        color: white;
    }

}