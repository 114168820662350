@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.cont {
    display: flex;
}

.App {
    flex-grow: 1;
    /*background: #d7d7d7;*/
}

.appStyle {
    flex: 4;
    width: 100vh;
    background-color: whitesmoke
}

@media only screen and (max-width: 1000px) {
    .appStyle {
        flex: 0;
        width: 100vw;
    }

    .cont {
        width: 100vw;
    }


}

@media screen and (min-width: 860px) {
    .register .col-2 img {
        display: initial;
    }
}