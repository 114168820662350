.sidebar {
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    background-color: #101A32;
    -webkit-box-shadow: 5px 5px 9px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 5px 9px 2px rgba(0, 0, 0, 0.2);
    position: sticky;
    top: 0;
}

.sidebarWrapper {
    /*padding: 20px;*/
    color: #555;

}

/*.sidebarMenu{*/
/*    margin-bottom: 10px;*/
/*}*/

.sidebarTitle {
    font-size: 15px;
    color: white;
    padding: 5px;
    width: calc(100% - 10px);
    display: flex;
    justify-content: center;
    background-color: #143246;
}

.sidebarList {
    list-style: none;
    padding: 5px;
    text-decoration: none;
    color: inherit;
}

.linkItem {
    text-decoration: none;
    color: inherit;
}

.sidebarListItem {
    text-decoration: none;
    color: white;
    padding: 5px 0;
    margin-bottom: 2px;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    align-items: center;
    width: 100%;
    height: 20px;
    border-radius: 5px;

}

.sidebarListItem.active {
    background-color: #282846;
    border: 1px solid #398DD2;
    /*border: 1px solid #2ff501;*/
    pointer-events: none;
    /*border-right-color: #2ff501;*/
}

.sidebarListItem:hover {
    background-color: #464650;
    /*border-right-color: #2ff501;*/
}

.sidebarIcon {
    /*margin-right: 5px;*/
    font-size: 20px !important;
    flex: 30%;
}

.sidebarPageName {
    flex: 70%;
    pointer-events: none;
}

@media only screen and (max-width: 1000px) {
    .sidebar {
        position: fixed;
        top: 0;
        left: 0;
        /*left: -400px;*/
        z-index: 9999;
    }

}