body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    min-width: 1000px;
    width: auto !important; /* Firefox will set width as auto */
    width: 1000px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error {
    color: #fc8181;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 0.25rem;
}

input.input_error,
select.input_error {
    border-color: #fc8181;
}

button:disabled {
    opacity: 0.35;
}

input:focus,
select:focus {
    border-color: #4299e1;
}

input::placeholder,
select::placeholder {
    color: #a0aec0;
}
@media only screen and (max-width:1000px)  {
    body {
        min-width: 100vw;
        width: 100vw; /* Firefox will set width as auto */
        width: 100vw;
    }

}

