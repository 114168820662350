@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* > * {
    margin: 0;
}

.login {
    flex: 4;
    height: 100vh;
}

.fullPage {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    /*backgroundColor: #E4E6E8;*/
    background: linear-gradient(#FFEDCB, #3D4856);
    /*background: linear-gradient(#FFEDCB, #897456);*/
    align-items: center;
}

.register {
    max-width: 978px;
    width: 100%;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    /* box-shadow: 1px 3px 10px #e9ecef; */
    background-color: white;
    /*zoom: 85%;*/
    margin: 0 auto;
}

.Authentication {
    background: #0f0c29; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    /*!*height: 100%;*!*/
    /*height: 600px;*/
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    text-align: center;
    /*remove scroll bar*/
    overflow: hidden;
}


.register span {
    color: #adb5bd;
}

#form {
    max-width: 320px;
    width: 100vw;
    margin: 2em auto;
}

#form > input, .btn {
    border: 1px solid #e9ecef;
    padding: .9em 1em;
}

#form > input:focus {
    outline: none;
}

#form > .btn {
    background-color: #003049;
    color: #e9ecef;
    font-size: 1em;
    cursor: pointer;
}

#form > span {
    font-size: 0.8em;
}

#form > span > a {
    text-decoration: none;
}


.flex {
    display: flex;
    gap: 1em;
}

.flex-col {
    flex-direction: column;
}

.register .col-1-auth {
    padding: 2em;
}

.register .col-2-auth img {
    width: 480px;
    height: 100%;
    object-fit: cover;
    background-repeat: no-repeat;
    /*background-size: cover;*/
    background-position: center;
    align-self: flex-end;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: none;
}

@media screen and (min-width: 860px) {

    .register {
        /*zoom: 100%;*/
        width: 50%;
        /*display: flex;*/
    }

    .register .col-1-auth {
        padding: 2em;
    }

    .register .col-2-auth img {
        display: initial;
    }
}

/*  Test notifications*/

