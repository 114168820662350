.messages {
    flex: 4;
}

.messageContainer {
    position: relative;
    display: flex;
    width: 100%;
    height: calc(100vh - 65px);
}

.messageContainer .rightSide {
    position: relative;
    flex: 70%;
    /*background: #e5ddd5;*/
    background: url(logo192.png);
}

.messageContainer .rightSide::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    right: 100%;
    background: url(logo192.png);
}

.header {
    position: relative;
    width: 100%;
    height: 60px;
    background: #ededed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 0 5px 0 5px;*/
    /*padding: 0 15px;*/
}

.userimg {
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 5px;
}

.cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /*padding-left: 5px;*/
}

.nav_icons {
    display: flex;
}

.nav_icons li {
    display: flex;
    list-style: none;
    cursor: pointer;
    color: #51585c;
    font-size: 1.5em;
    margin-left: 20px;
}

.search_chat {
    position: relative;
    width: 100%;
    height: 60px;
    background: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    /*padding: 0 10px;*/
}

.search_chat div {
    width: 100%;
    padding: 0 10px;
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*align-items: center;*/
}

.search_chat div input {
    width: calc(90%);
    outline: none;
    border: none;
    background: #fff;
    height: 40px;
    border-radius: 30px;
    font-size: 14px;
    padding: 0 0 0 40px;
}

.search_chat div input::placeholder {
    color: #bbb;
}

.chatlist {
    position: relative;
    height: calc(100% - 120px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.chat_box:hover {
    background: #efefef;
}

.imgText {
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgText h4 {
    font-weight: 500;
    line-height: 1.2em;
    margin-left: 15px;
}

.imgText h4 span {
    font-size: 0.8em;
    color: #555;
}

.chatBox {
    position: relative;
    width: calc(100% - 100px);
    height: calc(100% - 160px);
    padding: 50px;
    overflow-y: auto;
}

.message {
    position: relative;
    display: flex;
    width: 100%;
    margin: 5px 0;
}

.message p {
    position: relative;
    right: 0;
    text-align: right;
    max-width: 65%;
    padding: 12px;
    background: #dcf8c6;
    border-radius: 10px;
    font-size: 0.9em;
}

.message p::before {
    content: '';
    position: absolute;
    top: 0;
    right: -12px;
    width: 20px;
    height: 20px;
    background: linear-gradient(135deg, #dcf8c6 0%, #dcf8c6 50%, transparent 50%, transparent);
}

.message p span {
    display: block;
    margin-top: 5px;
    font-size: 0.85em;
    opacity: 0.5;
}

.my_message {
    justify-content: flex-end;
}

.my_message p {
    text-align: left;
    overflow-wrap: break-word;
}

.my_message p .time {
    text-align: right;
}

.frnd_message {
    justify-content: flex-start;
}

.frnd_message p {
    background: #fff;
    text-align: left;
    overflow-wrap: break-word;
}

.frnd_message p .time {
    text-align: right;
}

.message.frnd_message p::before {
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    width: 20px;
    height: 20px;
    background: linear-gradient(225deg, #fff 0%, #fff 50%, transparent 50%, transparent);
}

.chatbox_input {
    position: relative;
    width: 100%;
    min-height: 60px;
    background: #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ion-icon {
    cursor: pointer;
    font-size: 1.8em;
    color: #51585c;
    margin-left: 15px;
}

.chatbox_input input {
    position: relative;
    width: 90%;
    margin: 0 20px;
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 30px;
    font-size: 1em;
    word-break: break-word;
}




