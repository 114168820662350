.featured{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.featuredTop{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.featuredBottom{
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
}
.featuredItem{
    flex:1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-box-shadow: 5px 5px 9px 2px rgba(0,0,0,0.2);
    box-shadow: 5px 5px 9px 2px rgba(0,0,0,0.2);
}
.featuredTitle{
    font-size: 20px;
    /*color: #398DD2;*/
}

.featuredMoneyContainer{
    margin:10px 0px;
    display: flex;
    align-items: center;
}
.featuredMoney{
    font-size: 20px;
    font-weight: 600;
    width: 100%;
}
.featuredMoneyRate{
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.featuredIcon{
    font-size: 14px !important;
    margin-left: 5px;
}

.featuredIcon.negative{
    color: red;
}

.featuredSub{
    font-size: 15px;
    color: #555;
}

@media only screen and (max-width:700px)  {
    .featured{
        width: 100vw;
    }
    .featuredTop{
        width: 100vw;
        flex-direction: column;
    }
    .featuredBottom{
        width: 100vw;
        flex-direction: column;
    }
    .featuredItem{
        flex:1;
        margin: 10px 20px;
        padding: 30px;
        border-radius: 5px;
        cursor: pointer;
        -webkit-box-shadow: 5px 5px 9px 2px rgba(0,0,0,0.2);
        box-shadow: 5px 5px 9px 2px rgba(0,0,0,0.2);
    }

}